import React from "react"
import theme from "theme"
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | Тенісні корти Cleverus Land</title>
        <meta name={"description"} content={"Де тренуються чемпіони"} />
        <meta
          property={"og:title"}
          content={"Головна | Тенісні корти Cleverus Land"}
        />
        <meta property={"og:description"} content={"Де тренуються чемпіони"} />
        <meta
          property={"og:image"}
          content={"https://cleverusland.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://cleverusland.com/img/935231-446.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://cleverusland.com/img/935231-446.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://cleverusland.com/img/935231-446.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://cleverusland.com/img/935231-446.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://cleverusland.com/img/935231-446.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://cleverusland.com/img/935231-446.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="#e9f3ff"
        padding="0 0 0 0"
        inner-width="100%"
        quarkly-title="Stages/Steps-1"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
        >
          <Image
            src="https://cleverusland.com/img/1.jpg"
            display="block"
            width="45%"
            height="100%"
            object-fit="cover"
            md-width="100%"
            md-height="500px"
            sm-height="400px"
          />
          <Box
            min-width="100px"
            min-height="100px"
            width="55%"
            padding="70px 70px 70px 70px"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="center"
            lg-padding="50px 50px 50px 50px"
            md-width="100%"
            md-padding="80px 50px 80px 50px"
            sm-padding="80px 25px 80px 25px"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 900 42px/1.2 --fontFamily-mono"
            >
              Тенісні корти Cleverus Land
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansVerdana"
            >
              Ми дуже раді, що ви тут, і не можемо дочекатися, щоб показати вам,
              чому наш заклад є найкращим вибором для ваших тенісних потреб.
              Незалежно від того, чи ви досвідчений професіонал, чи тільки
              починаєте грати в теніс, наші корти та зручності розроблені, щоб
              надати вам неперевершений досвід тенісу.
            </Text>
            <Button
              background="#0b70de"
              color="--light"
              border-width="2px"
              border-style="solid"
              border-color="--color-light"
              padding="12px 30px 12px 30px"
              font="normal 300 16px/1.5 --fontFamily-mono"
              href="/contacts"
              type="link"
              text-decoration-line="initial"
            >
              Знайти Нас
            </Button>
          </Box>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        quarkly-title="Content-11"
        background="#e9f3ff"
      >
        <Override
          slot="SectionContent"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          align-items="start"
          lg-grid-template-columns="1fr"
        />
        <Box
          min-width="10px"
          min-height="10px"
          margin="0px 50px 0px 0px"
          lg-margin="0px 0 50px 0px"
        >
          <Text
            margin="0px 0px 30px 0px"
            font="normal 600 42px/1.2 --fontFamily-sans"
            letter-spacing="8px"
            white-space="nowrap"
            color="#0b70de"
            sm-white-space="normal"
          >
            Наша місія
          </Text>
          <Text
            margin="0px 0px 50px 0px"
            font="--lead"
            overflow-wrap="normal"
            word-break="normal"
            text-overflow="clip"
            hyphens="none"
            lg-margin="0px 0px 35px 0px"
          >
            Наша місія полягає в наданні високоякісних тенісних споруд і послуг,
            які задовольняють потреби всіх любителів тенісу. Ми прагнемо
            створити спільноту, де гравці зможуть процвітати, змагатися та
            розважатися. Орієнтуючись на досконалість, ми прагнемо запропонувати
            чудовий досвід, який виділяється серед конкурентів.
          </Text>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          sm-flex-direction="column"
        >
          <Image
            src="https://cleverusland.com/img/2.jpg"
            display="block"
            margin="0px 35px 0px 0px"
            sm-margin="0px 0 30px 0px"
          />
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-5"
        background="#e9f3ff"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          lg-align-items="center"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          lg-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          lg-flex-direction="column"
          width="100%"
          flex-direction="row"
          align-items="flex-start"
          lg-width="100%"
        >
          <Text
            margin="0px 36px 00px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            width="30%"
            lg-width="100%"
            lg-margin="24px 0px 30px 0px"
            lg-order="1"
            md-text-align="left"
          >
            Приходьте та відчуйте різницю на тенісних кортах Cleverus Land. Забронюйте свій корт сьогодні та зробіть перший крок до того, щоб стати чемпіоном.
          </Text>
          <Box
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            padding="0px 0px 25% 0px"
            overflow-x="hidden"
            overflow-y="hidden"
            transition="transform 0.2s ease-in-out 0s"
            lg-padding="0px 0px 50% 0px"
            lg-width="100%"
            width="55%"
            height="auto"
          >
            <Image
              src="https://cleverusland.com/img/3.jpg"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              object-fit="cover"
              position="absolute"
              display="block"
              width="100%"
              left={0}
            />
          </Box>
        </Box>
        <Box
          justify-content="center"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
          width="45%"
          lg-width="100%"
          align-items="flex-start"
        >
          <Box
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            overflow-x="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            overflow-y="hidden"
            position="relative"
            padding="0px 0px 150% 0px"
            lg-padding="0px 0px 100% 0px"
          >
            <Image
              object-fit="cover"
              display="block"
              top={0}
              lg-right="0px"
              lg-width="100%"
              bottom={0}
              min-height="100%"
              lg-left="-1px"
              src="https://cleverusland.com/img/4.jpg"
              position="absolute"
              width="100%"
              left={0}
              right={0}
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          align-items="flex-start"
          lg-align-items="center"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
          width="55%"
          lg-order="1"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 40% 0px"
          >
            <Image
              src="https://cleverusland.com/img/5.jpg"
              object-fit="cover"
              left={0}
              min-height="100%"
              position="absolute"
              display="block"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
            />
          </Box>
          <Text
            lg-text-align="center"
            margin="24px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            md-text-align="left"
          >
            Корти преміум-класу: наші тенісні корти ретельно обслуговуються, щоб
            забезпечити найкращі умови для гри. Поверхня ідеально підходить як
            для звичайних ігор, так і для змагань.
            <br />
            <br />
            Зручне розташування: завдяки нашому легкому доступу з різних
            куточків міста вам буде легко вийти на корт і почати гру. Доступні
            ціни: ми пропонуємо конкурентоспроможні ціни, гарантуючи, що ви
            отримаєте найкраще співвідношення ціни та якості.
            <br />
            <br />
            Доброзичливий персонал. Наша команда прагне забезпечити відмінне
            обслуговування клієнтів, починаючи від бронювання місця в суді й
            закінчуючи наданням допомоги на місці.
            <br />
            <br />
            Розширена система бронювання: наша проста у використанні система
            бронювання дозволяє вам зарезервувати час у суді заздалегідь, тому
            вам ніколи не доведеться турбуватися про наявність.
          </Text>
        </Box>
      </Section>
      <Components.Contacts />
      <Components.FooterMenu />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
